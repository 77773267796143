a {
  color: #23B0EC;
}

header .brand a.logo img {
  max-width: 260px;
}

header .right {
  text-align: right;
  margin-top: 40px;
}

#myNavbar {
  margin-top: 20px;
  background-color: #f5f5f5;
}

.navbar-toggle {
  background-color: #23B0EC;
}

.navbar-toggle .icon-bar {
  background-color: white;
}

@media screen and (max-width: 992px) {
  header .right {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .container > .navbar-header {
    background-color: #f5f5f5;
    margin-top: 20px;
  }
  #myNavbar {
    margin-top: 0;
  }
}

.search {
  margin-bottom: 20px;
}

.search form {
  display: block;
  width: 100%;
  text-align: center;
  min-height: 60px;
  margin: 10px auto 20px;
  padding: 10px;
}

.search form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.search form button {
  width: 100%;
  padding: 10px;
  background-color: #23B0EC;
  color: #FFF;
  border: 1px solid #23B0EC;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

input[type="text"], input[type="email"], textarea {
  width: 100%;
  padding: 10px;
  margin: 0 auto 20px;
  border: 1px solid #ddd;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

input[type="submit"] {
  background-color: #23B0EC;
  border: 1px solid #23B0EC;
  color: #fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 20px;
}

main h1, main h3 {
  color: #23B0EC;
}

ul.locations, ul.keywords, ul.employers {
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #f5f5f5;
  margin: 20px auto 40px;
  -webkit-padding-start: 30px;
}

ul.locations li, ul.keywords li, ul.employers li {
  padding: 5px 10px 5px 0;
  flex-basis: 32%;
}

ul.locations li {
  flex-basis: 19%;
}

@media screen and (max-width: 700px) {
  ul.locations li {
    flex-basis: 32%;
  }
}

@media screen and (max-width: 500px) {
  ul.locations li, ul.keywords li, ul.employers li {
    flex-basis: 49%;
  }
}

footer .container {
  text-align: center;
  margin: 10px auto;
  border-top: 1px solid #ddd;
  padding: 10px;
}

footer .container a {
  font-size: 11px;
  border-right: 1px solid #f5f5f5;
  padding-right: 5px;
  margin-right: 5px;
}

footer .container a:nth-child(8) {
  border-right: 0 !important;
}

footer .container .copyright {
  display: block;
  margin: 20px auto;
}

h3.accordion.active, h3.accordion:hover {
  background-color: #f5f5f5 !important;
}
